import React from 'react';
import { Button, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import {api} from '../../../../../../config/axios';
import Swal from 'sweetalert2';
import { getCurrentDateTime } from '../../../../../../config/functions';

// ICONOS
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// COMPONENTES
import MyMoneyInput from '../../../../../../components/inputs/MyMoneyInput';

const Formulario = ({ idTurno, saldoFinal, refresh }) => {

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        // VALIDAMOS QUE SE HAYA AGREGADO UN MONTO DE SALDO

        if(values.monto_final === ''){

            Swal.fire({
                icon: 'warning',
                title: 'Ingrese el monto de efectivo en caja para continuar'
            });

            return;

        }

        // ARMAMOS EL JSON DE DATOS PARA MANDAR

        const diferencia = parseFloat(saldoFinal.replace(/[^\d.-]/g, '')) - parseFloat(values.monto_final.replace(/[^\d.-]/g, ''));

        const data = {

            monto_final: values.monto_final,
            fecha_cierre: getCurrentDateTime(),
            monto_diferencia: `$${diferencia.toFixed(2)}`,
            id: idTurno

        };

        // URL DEL ENDPOINT

        const url = 'ordenes/turno/update';

        // MANDAMOS EL POST

        api.put(url, data)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                });

                refresh();

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Formik
            initialValues={{
                monto_final: '', 
            }}
        >
        {({ values }) => (

            <Form>

                <Grid
                    container
                    spacing={2}
                    paddingBottom={2}
                    marginTop={2}
                >

                    {/* CONFIRMAR SALDO */}

                    <Grid item xs={10}>

                        <MyMoneyInput
                            name="monto_final"
                            label="Saldo en Caja Final"
                        />

                    </Grid>

                    {/* BOTON PARA GUARDAR */}

                    <Grid item xs={2}>

                        <Button 
                            type="button" 
                            variant="contained" 
                            sx={{ color: "white", height: '100%' }} 
                            color="success" 
                            onClick={() => handleSubmit(values)}
                        >
                            <CheckCircleIcon />
                        </Button>

                    </Grid>

                </Grid>

            </Form>

        )}
        </Formik>

    )

}

export default Formulario