import React, {useState, useEffect} from 'react'
import { ColorModeContext, useMode } from './theme';
import { tokens } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Topbar from "../src/components/topbar/Topbar";
import Dashboard from "./scenes/dashboard";

// COMPONENTES
import MainSidebar from './components/sidebar/MainSidebar';
import Login from './pages/login/Login';

// CATALOGOS
import Categorias from './pages/catalogos/categorias/Categorias';
import Inventario from './pages/catalogos/inventario/Inventario';
import Mesas from './pages/catalogos/mesas/Mesas';
import Sucursales from './pages/catalogos/sucursales/Sucursales';
import Usuarios from './pages/catalogos/usuarios/Usuarios';
import Ordenes from './pages/ordenes/Ordenes';
import CerrarTurno from './pages/ordenes/turnos/cerrarTurno/CerrarTurno';
import NominaEmpleados from './pages/catalogos/nomina_empleados/NominaEmpleados';
import Proveedores from './pages/catalogos/proveedores/Proveedores';
import Gastos from './pages/gastos/Gastos';
import Reporte from './pages/reporte/Reporte';

function App() {

  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  // ===============================================  
  // LOGIN
  // =============================================== 

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {

    if(Cookies.get('login') === "1"){

      setShowLogin(false);

      const ruta = Cookies.get('modulo');

      navigate(`/${ruta}`);

    }else{

      navigate(`/`);

    }

  },[])

  // ===============================================

  return (

    <ColorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        <CssBaseline>

          <div className='app'>

            {showLogin ? null : <MainSidebar />}

            <main 
              className='content' 
              style={{
                backgroundColor: colors.primary[1000], 
                minHeight: '100vh',
                overflow: 'auto'
              }}
            >

              {showLogin ? null : <Topbar setShowLogin={setShowLogin}/>}

              <Routes>

                {/* INICIO */}
                <Route path='/' element={<Login setShowLogin={setShowLogin}/>}/>
                <Route path='/dashboard' element={<Dashboard/>}/>

                {/* CATALOGOS */}
                <Route path='/categorias' element={<Categorias/>}/>
                <Route path='/inventario' element={<Inventario/>}/>
                <Route path='/mesas' element={<Mesas/>}/>
                <Route path='/sucursales' element={<Sucursales/>}/>
                <Route path='/usuarios' element={<Usuarios/>}/>
                <Route path='/nomina_empleados' element={<NominaEmpleados/>}/>
                <Route path='/proveedores' element={<Proveedores/>}/>

                {/* ORDENES */}
                <Route path='/ordenes' element={<Ordenes/>}/>
                <Route path='/corte_caja' element={<CerrarTurno/>}/>

                <Route path='/gastos' element={<Gastos/>}/>
                <Route path='/reporte' element={<Reporte/>}/>

              </Routes>

            </main>

          </div>

        </CssBaseline>

      </ThemeProvider>

    </ColorModeContext.Provider>

  )
  
}

export default App