import React from 'react';
import logok9 from '../../assets/img/logo.jpeg'
import Cookies from 'js-cookie';
import md5 from 'md5';
import { Alert } from "@mui/material";
import bgLogin from '../../assets/img/bg-login.jpg';

// LIBRERIAS

import axios from 'axios';
import {baseURL} from '../../config/url';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

const Login = ({setShowLogin}) => {

  const navigate = useNavigate();

  // ===============================================
  // VALORES PARA EL FORMULARIO
  // ===============================================

  const [userData, setUserData] = useState({

    user: '',
    password: '',
    showWarning: false,
    warningText: ''

  })

  // ===============================================
  // ACTUALIZAR VALORES DEL EMIAL Y EL PASSWORD
  // ===============================================

  const handleChange = (event) => {

    const val = event.target.value;
    const name = event.target.name;

    setUserData({
      ...userData, [name]: val
    })

  }

  // ===============================================  
  // FUNCION PARA EL SUBMIT DEL LOGIN
  // =============================================== 

  const handleLogin = () => {

    if(userData.user !== '' && userData.password !== ''){

      axios.post(`${baseURL}api/usuarios/login`,{

        user: userData.user,
        password: md5(userData.password)
  
      })
      .then(result => {
        
        if(result.data.auth === true)
        {
          
          const rel_sucursal = result.data.rel_sucursal;
          const token = result.data.token;
          const name = result.data.name;
          const rol = result.data.rol;
          const sucursal = result.data.sucursal;
          const user = result.data.user;
          const iva = result.data.iva;
          const aplica_iva = result.data.aplica_iva;
          const costo_envio = result.data.costo_envio;
          const impresora = result.data.impresora;
          const expires = new Date();

          expires.setDate(expires.getDate() + 1);
  
          Cookies.set('login', "1", {expires});
          Cookies.set('token', token, {expires});
          Cookies.set('rel_sucursal', rel_sucursal, {expires});
          Cookies.set('name', name, {expires});
          Cookies.set('rol', rol, {expires});
          Cookies.set('sucursal', sucursal, {expires});
          Cookies.set('idUser', user, {expires});
          Cookies.set('iva', iva, {expires});
          Cookies.set('aplica_iva', aplica_iva, {expires});
          Cookies.set('costo_envio', costo_envio, {expires});

          setShowLogin(false);

          setUserData({
            ...userData, ['showWarning']: false
          })

          if(rol === "ADMIN"){

            navigate('/dashboard');

          } else {

            navigate('/ordenes');

          }   

        }
        else
        {

          setUserData({
            ...userData, 
            ['showWarning']: true, 
            ['warningText']: result.data.message
          })

        }
  
      })
      .catch(error => {
  
        //setErr(false);
        console.log(error)
  
      })

    }

  };

    return (

        <div 
          className="login-container" 
          style={{
            backgroundImage: `url(${bgLogin})`,
            backgroundSize: '100% 100%', 
            boxShadow: "5px 10px 8px #888888"
          }}>

          <form autoComplete="off">

            <div className="login-box">

              <div className="logo-container">
                <img src={logok9} style={{width: "200px"}}/>
              </div>

              <input 
                name="user"
                type="email" 
                placeholder="Usuario" className="input-field" 
                onChange={handleChange}
              />

              <input 
                name="password"
                type="password" 
                placeholder="Contraseña" 
                className="input-field"
                onChange={handleChange} 
              />

              {
                userData.showWarning
                ?
                (

                  <Alert 
                    severity="warning" 
                    sx={{
                      marginBottom: '10px', 
                      width: '100%',
                      fontWeight: 'bold'
                    }}
                  >
                    {userData.warningText}
                  </Alert>

                )
                :
                null
              }

                <button className="login-button" type='button' onClick={handleLogin}>Iniciar sesión</button>

              </div>

            </form>

        </div>
        
    )

}

// Estilos CSS

const styles = `

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }

  .background-image {
    /* Estilos para la imagen de fondo */
  }

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .logo-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    width: 300px;
    height: 40px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .login-button {
    width: 150px;
    height: 40px;
    background: #4FA238;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px
  }
    
  @media (max-width: 600px) {
  .input-field {
    width: 90%;
  }

  .login-button {
    width: 90%;
  }

  .login-box {
    width: 95%;
    padding: 15px;
  }
}

`;

// Aplicar los estilos al componente
const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Login