import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Divider } from "@mui/material";
import {api} from '../../config/axios';
import { marcarURL } from '../../config/functions';
import { formatoUTCparaIso } from '../../config/functions';

//COMPONENTES
import MiTabla from '../../components/plantilla/MiTabla';
import Formulario from './formulario/Formulario';

const Reporte = () => {

    // ===============================================
    // FUNCION PARA BUSCAR ORDENES
    // ===============================================

    const [ordenes, setOrdenes] = useState([]);

    const buscarOrdenes = async ( start, end ) => {

        const response = await api.get(`ordenes/cerradas/${start}/${end}`);

        let tempOrders = [];

        if(response.data !== "Sin Resultados"){

            setOrdenes(response.data);
            tempOrders = response.data;

        }else{

            setOrdenes([]);

        }

        // BUSCAMOS LOS RETIROS
        buscarRetiros(start, end, tempOrders);

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA DE ORDENES
    // ===============================================

    const orderColumns = [

        {
            field: "num_mesa",
            headerName: "Mesa",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "total",
            headerName: "Total",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "propina_monto",
            headerName: "Propina",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "fecha",
            headerName: "Fecha",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA SELECCIONAR ORDEN
    // ===============================================

    const selectOrder = ( params ) => {

        buscarArticulos(params.row.id)

    }

    // ===============================================
    // ARTICULOS POR ORDEN
    // ===============================================

    const [articulos, setArticulos] = useState([]);

    const buscarArticulos = async ( idOrden ) => {

        const response = await api.get(`ordenes/articulos/${idOrden}`); 

        if(response.data !== "Sin Resultados"){

            setArticulos(response.data);

        }else{

            setArticulos([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA ARTICULOS DE LA ORDEN
    // ===============================================

    const itemColumns = [

        {
            field: "descripcion",
            headerName: "Descripcion",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "cantidad",
            headerName: "Cantidad",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "precio",
            headerName: "Precio",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA BUSCAR RETIROS
    // ===============================================

    const [retiros, setRetiros] = useState([]);

    const buscarRetiros = async ( start, end, orders ) => {

        const response = await api.get(`retiro/dinero/${start}/${end}`);

        let tempRetiros = [];

        if(response.data !== "Sin Resultados"){

            setRetiros(response.data);
            tempRetiros = response.data;

        } else {

            setRetiros([]);

        }

        calcularTotales(orders, tempRetiros);

    }

    // ===============================================
    // COLUMNAS PARA TABLA
    // ===============================================

    const retiroColumns = [

        {
            field: "user",
            headerName: "Usuario",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "monto",
            headerName: "Monto",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "timestamp",
            headerName: "Fecha",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (cell) => formatoUTCparaIso(cell.value),
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA BUSCAR FECHAS DE LA SEMANA EN CURSO
    // ===============================================

    const getCurrentWeek = () => {

        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 = domingo, 1 = lunes, ..., 6 = sábado

        // Calcular el lunes de la semana en curso
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

        // Calcular el domingo de la semana en curso
        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (7 - dayOfWeek));

        // Formatear las fechas en YYYY-MM-DD
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        return {
            start: formatDate(startOfWeek),
            end: formatDate(endOfWeek)
        };

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        marcarURL('reporte');

        const { start, end } = getCurrentWeek();

        buscarOrdenes(start, end);

    },[]);

    // ===============================================
    // ESTADO PARA RESUMEN DE TARJETAS
    // ===============================================

    const [reportData, setReportData] = useState({

        ingresos: '$0.00',
        retiros: '$0.00',
        propinas: '$0.00'

    });

    // ===============================================
    // FUNCIONES PARA CALCULAR TOTALES
    // ===============================================

    const calcularTotales = ( orders, salidas ) => {

        let ingreso_total = 0, retiros = 0, propinas = 0;

        // RECORREMOS LAS ORDENES PARA HACER LOS CALCULOS

        const ordersT = orders.map((item) => {

            const monto = item.total !== '$0.00' ? parseFloat(item.total.replace(/[^\d.-]/g, '')) : 0;
            const cambio = item.cambio !== '$0.00' ? parseFloat(item.cambio.replace(/[^\d.-]/g, '')) : 0;
            const propinaa = item.propina_monto !== '$0.00' ? parseFloat(item.propina_monto.replace(/[^\d.-]/g, '')) : 0;

            // SUMAMOS EL INGRESO TOTAL

            ingreso_total += monto;
            retiros += cambio;
            propinas += propinaa;

        });

        // RECORREMOS LOS RETIROS PARA HACER LOS CALCULOS

        const salidasT = salidas.map((item) => {

            retiros += parseFloat(item.monto.replace(/[^\d.-]/g, ''));

        })

        // ESPERAMOS A QUE SE RESUELVA EL CICLO

        Promise.all([ordersT, salidasT]).then(() => {

            // ACTUALIZAMOS LOS VALORES DEL DESGLOSE

            setReportData(prevData => ({

                ...prevData,
                ingresos: `$${ingreso_total.toFixed(2)}`,
                retiros: `$${retiros.toFixed(2)}`,
                propinas: `$${propinas.toFixed(2)}`,

            }))

        })

    }

    // ===============================================

    return (

        <Grid container sx={{ padding: 2 }}>

            {/* RESUMEN DE TOTALES */}

            <Grid item xs={12}>

                <Grid container>

                    {/* INGRESO DE DINERO */}

                    <Grid item xs={4} >

                        <Paper elevation={3} sx={{ margin: 1, padding: 1}}>

                            <Typography textAlign={'center'} variant='h3'>
                                Ingresos
                            </Typography>
                            <Typography textAlign={'center'} variant='h1'>
                                {reportData.ingresos}
                            </Typography>

                        </Paper>

                    </Grid>

                    {/* SALIDA DINERO */}

                    <Grid item xs={4}>

                        <Paper elevation={3} sx={{ margin: 1, padding: 1 }}>

                            <Typography textAlign={'center'} variant='h3'>
                                Salidas
                            </Typography>
                            <Typography textAlign={'center'} variant='h1'>
                                {reportData.retiros}
                            </Typography>

                        </Paper>

                    </Grid>

                    {/* PLATILLOS VENDIDOS */}

                    <Grid item xs={4}>

                        <Paper elevation={3} sx={{ margin: 1, padding: 1 }}>

                            <Typography textAlign={'center'} variant='h3'>
                                Propinas
                            </Typography>
                            <Typography textAlign={'center'} variant='h1'>
                                {reportData.propinas}
                            </Typography>

                        </Paper>

                    </Grid>

                </Grid>

            </Grid>

            {/* FORMULARIO PARA BUSCAR ORDENES DEL DIA */}

            <Grid item xs={12} padding={3}>

                <Formulario
                    refreshOrdenes={buscarOrdenes}
                />

            </Grid>

            {/* TABLA PARA ORDENES*/}

            <Grid item xs={4} padding={1}>

                <Typography
                    textAlign={'center'} 
                    variant='h2'    
                >
                    Ordenes
                </Typography>

                <Divider></Divider>

                <MiTabla
                    data={ordenes}
                    columns={orderColumns}
                    rowClick={selectOrder}
                />

            </Grid>

            {/* TABLA PARA ARTICULOS*/}

            <Grid item xs={4} padding={1}>

                <Typography
                    textAlign={'center'} 
                    variant='h2'    
                >
                    Articulos
                </Typography>

                <Divider></Divider>

                <MiTabla
                    data={articulos}
                    columns={itemColumns}
                    rowClick={null}
                />

            </Grid>

            {/* TABLA PARA RETIROS*/}

            <Grid item xs={4} padding={1}>

                <Typography
                    textAlign={'center'} 
                    variant='h2'    
                >
                    Salidas
                </Typography>

                <Divider></Divider>

                <MiTabla
                    data={retiros}
                    columns={retiroColumns}
                    rowClick={null}
                />

            </Grid>

        </Grid>

    )

}

export default Reporte