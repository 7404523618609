import React from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";

const MiTabla = ({data, columns, rowClick, margin = '40px 0 0 0', density = "standard"}) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================

    return (

        <Box
            m={margin}
            height="75vh"
            width="100%"
            sx={{
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.main[700],
                borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.main[700],
            },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`
            },
            "& .red-cell": {
                backgroundColor: '#F08813',
                color: 'white',
                fontWeight: 'bold'
            },
            "& .green-cell": {
                backgroundColor: '#19D421',
                color: 'white',
                fontWeight: 'bold'
            }
            }}
        >
            <DataGrid 
                rows={data} 
                columns={columns} 
                components={{ Toolbar: GridToolbar}}
                onRowClick={rowClick}
                density={density}
            />
        </Box>

    )

}

export default MiTabla