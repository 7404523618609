import React, { useState } from 'react';
import { Box, Button, Grid, Typography, Alert } from '@mui/material';
import Cookies from 'js-cookie';

// ICONOS
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ModalIniciarTurno from './ModalIniciarTurno';

const IniciarTurno = ({ refresh }) => {

    // ===============================================
    // FUNCIONES Y ESTADO PARA CONTROLAR MODAL
    // ===============================================

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {

        setShowModal(true);

    }

    const closeModal = () => {

        setShowModal(false);

    }

    // ===============================================

    return (

        <Box>

            <Grid container>

                <Grid item xs={1}></Grid>

                <Grid item xs={10} sx={{ marginTop: 3, padding: 2, display: 'flex', justifyContent: 'center' }}>

                    <Alert
                        severity="info"
                        variant='outlined'
                        sx={{ width: '50%', fontSize: 18}}
                    >
                        Buen dia {Cookies.get('name')}, inicia turno para continuar
                    </Alert>

                </Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={12} sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>

                    <Button 
                        startIcon={<AccessTimeIcon style={{ fontSize: 30 }}/>}
                        onClick={openModal}
                        sx={{
                            color: 'white',
                
                            backgroundColor: 'green',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#005F2E',
                                color: 'white', 
                            },
                        }}
                    >
                        Iniciar Turno
                    </Button>

                </Grid>

            </Grid>

            {/* MODAL PARA ABRIR TURNO */}

            <ModalIniciarTurno
                open={showModal}
                onClose={closeModal}
                refresh={refresh}
            />

        </Box>

    )

}

export default IniciarTurno