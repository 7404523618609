import { SubMenu } from "react-pro-sidebar";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ListIcon from '@mui/icons-material/List';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

import Item from "../componentes/Item";

const EmpleadoSidebar = ({ selected, setSelected }) => {

    return (

        <>

        {/* ORDENES */}

        <Item
            title="Ordenes"
            to="/ordenes"
            icon={<LocalDiningIcon />}
            selected={selected}
            setSelected={setSelected}
        />

        {/* CORTE CAJA */}

        <Item
            title="Corte De Caja"
            to="/corte_caja"
            icon={<PointOfSaleIcon />}
            selected={selected}
            setSelected={setSelected}
        />
        
        </>

    )

}

export default EmpleadoSidebar