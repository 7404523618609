import Cookies from 'js-cookie';

// FUNCION PARA MARCAR LA URL ACTUAL

export function marcarURL(url){

    // SETEAR RUTA DONDE NOS ENCONTRAMOS PARA EL REFRESH

    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    Cookies.set('modulo', url, {expires});

}

// FUNCION PARA HACER LOGOUT

export function logOutFunction(){

    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    Cookies.remove('token');
    Cookies.remove('modulo');
    Cookies.remove('idUser');
    Cookies.remove('name');
    Cookies.remove('rel_sucursal');
    Cookies.remove('rol');
    Cookies.remove('sucursal');
    Cookies.remove('iva');
    Cookies.remove('aplica_iva');
    Cookies.remove('costo_envio');
    Cookies.set('login', "0", {expires});

    window.location.href = '/';

}

// FUNCION PARA OBTENER LA FECHA HORA ACTUAL
// FORMATO YYYY-MM-DD HH:MM:SS

export function getCurrentDateTime(){

    // Obtener la fecha y hora actual
    var fechaHoraActual = new Date();

    // Obtener año, mes, día, hora, minuto y segundo
    var año = fechaHoraActual.getFullYear();
    var mes = ('0' + (fechaHoraActual.getMonth() + 1)).slice(-2); // Los meses van de 0 a 11, por eso se suma 1
    var dia = ('0' + fechaHoraActual.getDate()).slice(-2);
    var hora = ('0' + fechaHoraActual.getHours()).slice(-2);
    var minuto = ('0' + fechaHoraActual.getMinutes()).slice(-2);
    var segundo = ('0' + fechaHoraActual.getSeconds()).slice(-2);

    // Crear la cadena de fecha y hora en el formato deseado
    var fechaHoraFormateada = año + '-' + mes + '-' + dia + ' ' + hora + ':' + minuto + ':' + segundo;

    return fechaHoraFormateada;

}

// FUNCION PARA FORMATEAR FECHA
// FORMATO DD-MM-YYYY

export function formatDate(fechaOriginal){

    const fecha = new Date(fechaOriginal);
        
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses en JavaScript van de 0 a 11.
    const anio = fecha.getFullYear();
  
    return `${dia}-${mes}-${anio}`;

}

// FORMATEAR FECHA DE UTC A CENTRAL TIME
// FORMATO MM/DD/YYYY HH:MM:SS

export function formatoUTCparaIso(fechaOriginal){

// Crear objeto de fecha a partir de la cadena UTC
  var fecha = new Date(fechaOriginal);

  // Obtener componentes de la fecha y hora local
  var año = fecha.getFullYear();
  var mes = ('0' + (fecha.getMonth() + 1)).slice(-2);
  var dia = ('0' + fecha.getDate()).slice(-2);
  var hora = ('0' + fecha.getHours()).slice(-2);
  var minuto = ('0' + fecha.getMinutes()).slice(-2);
  var segundo = ('0' + fecha.getSeconds()).slice(-2);

  // Construir la cadena de fecha y hora en el formato deseado
  var fechaLocal = año + '-' + mes + '-' + dia + ' ' + hora + ':' + minuto + ':' + segundo;

  // Retornar la fecha y hora local
  return fechaLocal;

}

// FUNCION PARA FORMATEAR LPAD

export function lpad(str, length, padChar){

    str = String(str);

    while (str.length < length) {

        str = padChar + str;

    }
    
    return str;

}