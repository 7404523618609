import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import { getCurrentDateTime } from '../../../../config/functions';
import Cookies from 'js-cookie';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTE
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';

const ModalIniciarTurno = ({ open, onClose, refresh }) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        // ARMAMOS EL JSON DE DATOS PARA MANDAR

        const dateNow = getCurrentDateTime();
        const idUser = Cookies.get('idUser');

        const data = {

            rel_user: idUser,
            monto_inicial: values.monto_inicial,
            fecha_inicio: dateNow

        };

        const url = 'ordenes/turno/crear';

        // MANDAMOS EL POST

        api.post(url, data)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose()}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    monto_inicial: '', 
                }}
                validationSchema={Yup.object({
                    monto_inicial: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h5" align="center">
                            Ingresa el monto de efectivo que se encuentra en caja
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* CATEGORIA */}

                                <Grid item xs={12}>

                                    <MyMoneyInput
                                        name="monto_inicial"
                                        label="Efectivo En Caja"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                mt: 2 
                            }}
                        >

                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => onClose()}
                            >
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalIniciarTurno