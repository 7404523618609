import React from 'react';
import { IconButton } from "@mui/material";
import { logOutFunction } from '../../../../config/functions';

// ICONOS
import LogoutIcon from '@mui/icons-material/Logout';

const LogOut = () => {

    return (

        <IconButton onClick={()=>logOutFunction()}>
            <LogoutIcon />
        </IconButton>

    )

}

export default LogOut