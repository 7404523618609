import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import MiTabla from '../../../components/plantilla/MiTabla';
import ModalCrearCategoria from './modales/ModalCrearCategoria';
import ModalEditarCategoria from './modales/ModalEditarCategoria';

const Categorias = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR PERFILES
    // ===============================================

    const [data, setData] = useState([]);

    const buscarCategorias = async () => {

        const response = await api.get('categorias');

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "categoria",
            headerName: "Categoria",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODALES
    // ===============================================

    const [modal, setModal] = useState({

        crear: false,
        editar: false

    })

    // -----------------------------------------------

    const handleOpenModal = (target) => {

        setModal({ ...modal, [target]: true});

    };

    // -----------------------------------------------
    
    const handleCloseModal = (target) => {

        setModal({ ...modal, [target]: false});

    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const [formValues, setFormValues] = useState({});

    const handleRowClick = (params) => {

        setFormValues(params.row);

        handleOpenModal('editar');

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarCategorias();

        marcarURL('categorias');

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            {/* HEADER */}

            <Header title="Categorias" subtitle="Catalogo de Categorias"/>

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.main[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal('crear')}
            >
                Agregar Categoria
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

            {/* MODAL AGREGAR CATEGORIA */}

            <ModalCrearCategoria
                open={modal.crear}
                onClose={handleCloseModal}
                refresh={buscarCategorias}
            />

            {/* MODAL PARA EDITAR CATEGORIA */}

            <ModalEditarCategoria
                open={modal.editar}
                onClose={handleCloseModal}
                refresh={buscarCategorias}
                data={formValues}
            />

        </Box>

    )

}

export default Categorias