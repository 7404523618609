import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { api } from '../../../../config/axios';
import { Box, Grid, Alert } from '@mui/material';
import { formatoUTCparaIso, getCurrentDateTime } from '../../../../config/functions';

// COMPONENTES
import TablaResumen from './componentes/tablaResumen/TablaResumen';
import DesgloseTurno from './componentes/desglose/DesgloseTurno';
import TablaRetiros from './componentes/tablaRetiros/TablaRetiros';

const CerrarTurno = () => {

    const [warning, setWarning] = useState(null)

    // ===============================================
    // ESTADO PARA DESGLOSE
    // ===============================================

    const [desglose, setDesglose] = useState({

        saldo_inicial: 0,
        ingreso_total: 0,
        ingreso_efectivo: 0,
        cambio: 0,
        retiros: 0,
        saldo_final: 0

    });

    // ===============================================
    // FUNCION PARA TURNO ACTIVO
    // ===============================================

    const [idTurno, setIdTurno] = useState(0)

    const buscarTurnoActivo = async () => {

        const idUser = Cookies.get('idUser');

        const response = await api.get(`ordenes/turno/${idUser}`);

        if(response.data !== "Sin Resultados"){

            setIdTurno(response.data[0]['id']);
            setWarning(null);

            // BUSCAMOS LAS ORDENES ENTRE EL RANGO DE FECHAS DEL INICIO DE TURNO Y ESTE MOMENTO

            buscarOrdenes(response.data[0]);

        } else {

            setIdTurno(0);
            setWarning('Inicia un turno para continuar con el corte.')

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR ORDENES ENTRE RANGO DE FECHAS
    // ===============================================

    const [ordenes, setOrdenes] = useState([]);

    const buscarOrdenes = async ( dataTurno ) => {

        const newStart = formatoUTCparaIso(dataTurno.fecha_inicio);
        const newEnd = getCurrentDateTime();

        let datosOrdenes = [];

        const response = await api.get(`ordenes/corte/caja/${newStart}/${newEnd}`);

        if(response.data !== "Sin Resultados"){

            // BUSCAMOS LAS ORDENES ENTRE EL RANGO DE FECHAS DEL INICIO DE TURNO Y ESTE MOMENTO

            let ordenesAbiertas = 0;

            const promesas = response.data.map((item) => {

                if(item.status !== 'Cerrada'){

                    ordenesAbiertas += 1;

                }

            });

            // ESPERAMOS A QUE SE RESUELVAN LAS PROMESAS

            Promise.all(promesas).then(() => {

                if(ordenesAbiertas > 0){

                    setIdTurno(10000);

                } else {

                    datosOrdenes = response.data;
                    setOrdenes(response.data);

                    // BUSCAMOS RETIROS DE DINERO

                    getRetiros(newStart, newEnd, dataTurno, datosOrdenes);

                }

            })

        } 

    }

    // ===============================================
    // FUNCION PARA BUSCAR RETIROS DE EFECTIVO
    // ===============================================

    const [retiros, setRetiros] = useState([]);

    const getRetiros = async (start, end, turno, ordenes) => {

        let datosRetiro = [];

        const response = await api.get(`retiro/dinero/${start}/${end}`);

        if(response.data !== "Sin Resultados"){

            datosRetiro = response.data;
            setRetiros(response.data);

        } else {

            datosRetiro = [];
            setRetiros([]);

        }

        // CALCULAMOS LOS TOTALES

        calcularTotales(turno, ordenes, datosRetiro);

    }

    // ===============================================
    // FUNCION PARA CALCULAR TOTALES
    // ===============================================

    const calcularTotales = (datosTurno, datosOrdenes, datosRetiros) => {

        const saldo_inicial = parseFloat(datosTurno.monto_inicial.replace(/[^\d.-]/g, ''));
        let ingreso_total = 0, ingreso_efectivo = 0, cambio = 0, retiros = 0;

        // RECORREMOS LAS ORDENES PARA HACER LOS CALCULOS

        const promesaOrdenes = datosOrdenes.map((item) => {

            const monto_efectivo = item.pago_efectivo !== '$0.00' ? parseFloat(item.pago_efectivo.replace(/[^\d.-]/g, '')) : 0;
            const monto_tarjeta = item.pago_tarjeta !== '$0.00' ? parseFloat(item.pago_tarjeta.replace(/[^\d.-]/g, '')) : 0;
            const newCambio = item.cambio !== '$0.00' ? parseFloat(item.cambio.replace(/[^\d.-]/g, '')) : 0;

            // VALIDAMOS SI EL PAGO FUE EN EFECTIVO

            if(item.pago_efectivo !== "$0.00"){

                ingreso_efectivo += monto_efectivo;
                cambio += newCambio;

            }

            // SUMAMOS EL INGRESO TOTAL

            ingreso_total += monto_efectivo + monto_tarjeta;

        });

        // RECORREMOS LOS RETIROS PARA HACER LOS CALCULOS

        const promesaRetiros = datosRetiros.map((item) => {

            retiros += parseFloat(item.monto.replace(/[^\d.-]/g, ''));

        })

        // ESPERAMOS A QUE SE RESUELVA EL CICLO

        Promise.all([promesaOrdenes, promesaRetiros]).then(() => {

            // CALCULAMOS EL SALDO FINAL EN CAJA

            const saldo_final = (saldo_inicial + ingreso_efectivo) - (cambio + retiros);

            // ACTUALIZAMOS LOS VALORES DEL DESGLOSE

            setDesglose(prevData => ({

                ...prevData,
                saldo_inicial: `$${saldo_inicial.toFixed(2)}`,
                ingreso_total: `$${ingreso_total.toFixed(2)}`,
                ingreso_efectivo: `$${ingreso_efectivo.toFixed(2)}`,
                cambio: `$${cambio.toFixed(2)}`,
                retiros: `$${retiros.toFixed(2)}`,
                saldo_final: `$${saldo_final.toFixed(2)}`

            }))

        })

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarTurnoActivo();

    },[]);

    // ===============================================

    return (

        <Box>

            <Grid container>

                {
                    idTurno === 0 ? (

                        <Grid 
                            item 
                            xs={12}
                            sx={{ 
                                marginTop: 3, 
                                padding: 2, 
                                display: 'flex', 
                                justifyContent: 'center' 
                            }}
                        >

                            <Alert 
                                variant="outlined" 
                                severity="warning"
                                sx={{ 
                                    width: '30%',
                                    fontSize: 20,
                                }}
                            >
                                Inicia un turno para realizar el corte.
                            </Alert>

                        </Grid>

                    ) : idTurno === 10000 ? (

                        <Grid 
                            item 
                            xs={12}
                            sx={{ 
                                marginTop: 3, 
                                padding: 2, 
                                display: 'flex', 
                                justifyContent: 'center' 
                            }}
                        >

                            <Alert 
                                variant="outlined" 
                                severity="warning"
                                sx={{ 
                                    width: '50%',
                                    fontSize: 20
                                }}
                            >
                                Cierre todas las ordenes para realizar el corte
                            </Alert>

                        </Grid>

                    ) : (

                        <>

                            {/* SECCION PARA RESUMEN DE ORDENES DEL TURNO */}

                            <Grid item xs={4} sx={{ padding: 2 }}>

                                <TablaResumen data = {ordenes} />

                            </Grid>

                            {/* SECCION PARA RESUMEN DE RETIROS DE EFECTIVO */}

                            <Grid item xs={4} sx={{ padding: 2 }}>

                                <TablaRetiros data = {retiros} />

                            </Grid>

                            {/* DESGLOCE DE EFECTIVO */}

                            <Grid item xs={4} sx={{ padding: 2 }}>

                                <DesgloseTurno
                                    desglose= { desglose }
                                    idTurno = { idTurno }
                                    refresh = { buscarTurnoActivo }
                                />

                            </Grid>
                        
                        </>

                    )
                }

            </Grid>

        </Box>

    )

}

export default CerrarTurno