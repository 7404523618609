import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Drawer } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import Cookies from 'js-cookie';
import AdminSidebar from './menus/AdminSidebar';
import EmpleadoSidebar from './menus/EmpleadoSidebar';

// ICONOS
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Logo from '../../assets/img/cilantros/icono.png';


const MainSidebar = () => {

    // ===============================================
    // ESTADOS PARA EL SIDEBAR
    // ===============================================

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [isMobile, setIsMobile] = useState(false);

    // ===============================================
    // COLORES PARA EL COMPONENTE
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VERIFICAR SI ES UN MOVIL
    // ===============================================

    const checkIsMobile = () => {

        setIsMobile(window.innerWidth < 768); // Puedes ajustar este valor según tus necesidades

    }; 

    // ===============================================
    // CONTROLAR ESTADO DEL SIDEBAR
    // ===============================================

    const toggleSidebar = () => {

        setIsCollapsed(!isCollapsed);

    };

    // ===============================================
    // CATALOGO DE SIDEBARS
    // ===============================================

    const [option, setOption] = useState('EMPLEADO');

    const catalago = {

        ADMIN: AdminSidebar,
        EMPLEADO: EmpleadoSidebar

    }

    const MenuActual = catalago[option];

    // ===============================================
    // USEEFFECT PARA CARGAR DATOS
    // ===============================================

    useEffect(() => {

        // VALIDAMOS SI ES MOVIL

        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        // VALIDAMOS EL PERFIL PARA EL MENU

        const rol = Cookies.get('rol');

        if(rol === "EMPLEADO"){

            setSelected("Ordenes");

        }

        
        setOption(rol)

        return () => {

            window.removeEventListener('resize', checkIsMobile);

        };

    },[])

    // ===============================================

    return (

        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                    paddingRight: isCollapsed ? '0px' : '50px'
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#5DBC43 !important",
                },
                "& .pro-menu-item.active": {
                    color: "#7BF658 !important",
                },
                minHeight: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >

            {/* Muestra un botón de menú en lugar del sidebar en dispositivos móviles */}

            {
                isMobile
                ?
                (
                    <IconButton
                        sx={{
                            zIndex: 1,
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            color: colors.grey[100],
                        }}
                        onClick={toggleSidebar}
                    >
                        <MenuOutlinedIcon />
                    </IconButton>
                )
                :
                (
                    <ProSidebar collapsed={isCollapsed} sx={{width: '100%'}}>

                        <Menu iconShape="square">

                            {/* LOGO AND MENU ICON */}

                            <MenuItem
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                                style={{
                                    margin: "10px 0 20px 0",
                                    color: colors.grey[100],
                                }}
                            >
                            {!isCollapsed && (

                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="15px"
                                >

                                    <Typography variant="h3" color={colors.grey[100]}></Typography>
                                    
                                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <MenuOutlinedIcon />
                                    </IconButton>

                                </Box>

                            )}
                            </MenuItem>

                            {!isCollapsed && (

                                <Box mb="25px">

                                    <Box display="flex" justifyContent="center" alignItems="center">

                                        <img
                                            alt="profile-user"
                                            width="140px"
                                            height="140px"
                                            src={Logo}
                                            style={{ 
                                                cursor: "pointer", 
                                            }}
                                        />

                                    </Box>

                                </Box>

                            )}

                            {/* MENU ACTUAL */}

                            <Box paddingLeft={isCollapsed ? undefined : "10%"}>

                                <MenuActual
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                            </Box>

                        </Menu>

                    </ProSidebar>
                )
            }

            {/* SECCION PARA RENDERIZAR SI SE ABRE DESDE UN DISPOSITIVO MOVIL */}

            {
                isMobile && (

                    <Drawer
                        anchor="left"
                        open={isCollapsed}
                        onClose={toggleSidebar}
                        variant="temporary"
                    >

                    <ProSidebar collapsed={isCollapsed} sx={{width: '100%'}}>

                        <Menu iconShape="square">

                            {/* LOGO AND MENU ICON */}

                            <MenuItem
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                                style={{
                                    margin: "10px 0 20px 0",
                                    color: colors.grey[100],
                                }}
                            >
                                {

                                    !isCollapsed && (

                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            ml="15px"
                                        >

                                            <Typography variant="h3" color={colors.grey[100]}></Typography>

                                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                                <MenuOutlinedIcon />
                                            </IconButton>

                                        </Box>
                                    )
                                }
                            </MenuItem>

                            {
                                !isCollapsed && (

                                    <Box mb="25px">

                                        <Box display="flex" justifyContent="center" alignItems="center">

                                            <img
                                                alt="profile-user"
                                                width="140px"
                                                height="140px"
                                                src={Logo}
                                                style={{ 
                                                    cursor: "pointer", 
                                                    borderRadius: "30%", 
                                                    backgroundColor: 'white', 
                                                    padding: '10px' 
                                                }}
                                            />
                                            
                                        </Box>

                                    </Box>

                                )
                            }

                            {/*  */}

                            <Box paddingLeft={isCollapsed ? undefined : "10%"}>

                                {/* DASHBOARD */}

                                <MenuActual
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                            </Box>

                        </Menu>

                    </ProSidebar>

                </Drawer>
            )}

        </Box>

    )

}

export default MainSidebar