import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { api } from '../../config/axios';
import Cookies from 'js-cookie';
import { marcarURL } from '../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

// COMPONENTES
import Controlador from './controlador/Controlador';
import AgregarOrden from './agregar_orden/AgregarOrden';
import CustomPanel from './subComponentes/CustomPanel';
import IniciarTurno from './turnos/iniciarTurno/IniciarTurno';

const Ordenes = () => {

    // ===============================================
    // FUNCION PARA BUSCAR ORDENES ACTIVAS
    // ===============================================

    const [ordenesActivas, setOrdenesActivas] = useState([]);

    const buscarOrdenes = async () => {

        const response = await api.get(`ordenes/activas`);

        if(response.data !== "Sin Resultados"){

            setOrdenesActivas(response.data);

        } else {

            setOrdenesActivas([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR MESAS
    // ===============================================

    const [mesas, setMesas] = useState([]);

    const buscarMesas = async () => {

        const sucursal = Cookies.get('rel_sucursal');

        const response = await api.get(`mesas/select/${sucursal}`);

        if(response.data !== "Sin Resultados"){

            setMesas(response.data);

        }else{

            setMesas([]);

        }

    }

    // ===============================================
    // FUNCION PARA TURNO ACTIVO
    // ===============================================

    const [turnoActivo, setTurnoActivo] = useState(false);

    const buscarTurnoActivo = async () => {

        const idUser = Cookies.get('idUser');

        const response = await api.get(`ordenes/turno/${idUser}`);

        if(response.data !== "Sin Resultados"){

            setTurnoActivo(true);

        } else {

            setTurnoActivo(false);

        }

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarOrdenes();

        buscarTurnoActivo();

        buscarMesas();

        marcarURL('ordenes');

    },[]);

    // ===============================================
    // SECCION PARA CONTROLAR ESTADOS DE LOS TABS
    // ===============================================

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {

        setTabValue(newValue);

    };

    // ===============================================
    // FUNCION PARA REFRESCAR VISTA
    // ===============================================

    const refreshVista = () => {

        setTabValue(0);
        buscarOrdenes();

    }

    // ===============================================

    return (

        <Box sx={{ width: '100%' }}>

            {/* VALIDAMOS SI EXISTE UN TURNO ACTIVO PARA EL USUARIO */}

            {
                turnoActivo ? (

                    <>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                        <Tabs 
                            value={tabValue} 
                            onChange={handleChange} 
                            aria-label="basic tabs example"
                            textColor="secondary"
                            indicatorColor="secondary"
                        >
                            <Tab icon={<AddCircleIcon/>} />

                            {
                                ordenesActivas.map((item, index) => (

                                    <Tab key={item.id} label={`Orden #${index+1}`} />

                                ))
                            }
                            
                        </Tabs>

                    </Box>

                    {/* TAB ESTATICO PARA AGREGAR ORDENES */}

                    <CustomPanel value={tabValue} index={0}>

                        <AgregarOrden
                            refreshOrdenes={buscarOrdenes}
                            mesas={mesas}
                        />

                    </CustomPanel>

                    {/* TABS DINAMICOS PARA ORDENES YA CREADAS */}

                    {
                        
                        ordenesActivas.map((item, index) => (

                            <CustomPanel key={item.id} value={tabValue} index={index + 1}>

                                <Controlador
                                    datosOrden={item}
                                    refreshOrdenes={refreshVista}
                                    mesas={mesas}
                                />

                            </CustomPanel>

                        ))
                    }

                    </>

                ) : (

                    <>
                    
                    {/* EN CASO DE NO HABER TURNO ACTIVO LO INICIAMOS */}

                    <IniciarTurno
                        refresh={buscarTurnoActivo}
                    />

                    </>

                )
            }

            

        </Box>

    );

}

export default Ordenes