import React, { useState } from 'react';
import { Button } from '@mui/material';

// ICONOS
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

// COMPONENTES
import ModalRetiro from './ModalRetiro';

const Btn_Retiro_Dinero = () => {

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODAL
    // ===============================================

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {

        setShowModal(false);

    }

    // ===============================================

    return (

        <React.Fragment>

            {/* BOTON PARA REGISTRAR RETIRO */}

            <Button 
                variant="contained" 
                color="info"
                //startIcon={<PointOfSaleIcon />}
                onClick={() => setShowModal(true)}
                sx={{
                    backgroundColor: '#0678C5asa',
                    color: 'white',
                    fontWeight: 'bold'
                }}
            >
                <PointOfSaleIcon />
            </Button>

            {/* MODAL PARA CAPTURAR RETIRO DE EFECTIVO */}

            <ModalRetiro
                open={showModal}
                onClose={closeModal}
            />

        </React.Fragment>

    )

}

export default Btn_Retiro_Dinero