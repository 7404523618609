import React, { useEffect, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import md5 from 'md5';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyAutoComplete from '../../../../components/inputs/MyAutoComplete';
import MySwitch from '../../../../components/inputs/MySwitch';
import MyInputPassword from '../../../../components/inputs/MyInputPassword';

const ModalEditarUsuario = ({ open, onClose, refresh, data, sucursales }) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALORES PARA ROLES
    // ===============================================

    const cat_roles = [

        {value: 'ADMIN', label: 'ADMIN'},
        {value: 'EMPLEADO', label: 'EMPLEADO'}

    ];

    // ===============================================
    // VALORES PARA FORMULARIO
    // ===============================================

    const [formValues, setFormValues] = useState({

        rel_sucursal: '', 
        nombres: '',
        apellidos: '',
        user: '',
        password: '',
        rol: '',
        activo: 0

    })

    // ===============================================
    // ACTUALIZAR VALORES DEL FORMULARIO
    // ===============================================

    useEffect(() => {

        if(Object.keys(data).length > 0){

            data.password = '';
            setFormValues(data);

        }

    }, [data]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'usuarios/update';

        // VALIDAMOS SI SE AGREGO UNA NUEVA CONTRASENA

        if(values.password !== ""){

            values.password = md5(values.password);

        } else {

            values.password = 'N/A';

        }

        // MANDAMOS EL POST

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose('editar');

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose('editar')}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={formValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    rel_sucursal: Yup.string().required('Requerido'),
                    nombres: Yup.string().required('Requerido'),
                    apellidos: Yup.string().required('Requerido'),
                    user: Yup.string().required('Requerido'),
                    rol: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `40%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Editar Usuario
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* NOMBRE S */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="nombres"
                                        label="Nombre(s)"
                                    />

                                </Grid>

                                {/* APELLIDO S */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="apellidos"
                                        label="Apellido(s)"
                                    />

                                </Grid>

                                {/* USUARIO */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="user"
                                        label="Usuario"
                                    />

                                </Grid>

                                {/* PASSWORD */}

                                <Grid item xs={6}>

                                    <MyInputPassword
                                        name="password"
                                        label="Contraseña"
                                    />

                                </Grid>

                                {/* SUCURSAL */}

                                <Grid item xs={6}>

                                    <MyAutoComplete
                                        name="rel_sucursal"
                                        label="Sucursal"
                                        options={sucursales}
                                    />

                                </Grid>

                                {/* ROL */}

                                <Grid item xs={6}>

                                    <MyAutoComplete
                                        name="rol"
                                        label="Rol"
                                        options={cat_roles}
                                    />

                                </Grid>

                                {/* ACTIVO */}

                                <Grid item xs={4}></Grid>

                                <Grid item xs={4}>

                                    <MySwitch
                                        name="activo"
                                        label="Activo"
                                    />

                                </Grid>

                                <Grid item xs={4}></Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('editar')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalEditarUsuario