import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import Cookies from 'js-cookie';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';
import TextAreaInput from '../../../../components/inputs/TextAreaInput';

const ModalRetiro = ({ open, onClose }) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'retiro/dinero/crear';

        // OBTENEMOS EL ID DEL USUARIO

        const idUser = Cookies.get('idUser');

        values.rel_user = idUser;

        // MANDAMOS EL POST

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose()}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    monto: '', 
                    comentario: ''
                }}
                validationSchema={Yup.object({
                    monto: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Registrar Salida
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* MONTO */}

                                <Grid item xs={12}>

                                    <MyMoneyInput
                                        name="monto"
                                        label="Monto"
                                    />

                                </Grid>

                                {/* COMENTARIO */}

                                <Grid item xs={12}>

                                    <TextAreaInput
                                        name="comentario"
                                        label="Comentario (Opcional)"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose()}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalRetiro