import { Grid, Typography, Chip, Divider } from '@mui/material'
import React from 'react';
import Formulario from '../formulario/Formulario';

const DesgloseTurno = ({ desglose, idTurno, refresh }) => {

    // ===============================================

    return (

        <Grid container>

            {/* HEADER */}

            <Grid item xs={12}>

                <Typography 
                    textAlign={'center'} 
                    variant='h2' 
                    marginBottom={1}
                >
                    Desglose:
                </Typography>

            </Grid>

            <Grid item xs={12} marginBottom={2}>

                <Divider></Divider>

            </Grid>

            {/* SALDO INICIAL */}

            <Grid item xs={12}>

                <Typography 
                    textAlign={'center'}
                    variant='h3'
                    fontWeight={'bold'}
                >
                    Saldo Inicial:
                </Typography>

            </Grid>

            <Grid 
                item 
                xs={12} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    marginTop: 1
                }}
            >

                <Chip 
                    label={desglose.saldo_inicial}
                    sx={{
                        padding: 3,
                        backgroundColor: '#808B96',
                        fontWeight: 'bold',
                        fontSize: 35
                    }}
                />

            </Grid>

            {/* INGRESO TOTAL */}

            <Grid item xs={6} marginTop={2}>

                <Typography 
                    textAlign={'center'}
                    variant='h3'
                    fontWeight={'bold'}
                >
                    Ingresos:
                </Typography>

            </Grid>

            {/* INGRESOS EFECTIVO */}

            <Grid item xs={6} marginTop={2}>

                <Typography 
                    textAlign={'center'}
                    variant='h3'
                    fontWeight={'bold'}
                >
                    Efectivo:
                </Typography>

            </Grid>

            {/* INGRESO TOTAL */}

            <Grid 
                item 
                xs={6} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    marginTop: 1
                }}
            >

                <Chip 
                    label={desglose.ingreso_total}
                    sx={{
                        padding: 3,
                        backgroundColor: 'green',
                        fontWeight: 'bold',
                        fontSize: 35
                    }}
                />

            </Grid>

            {/* INGRESO EFECTIVO */}

            <Grid 
                item 
                xs={6} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    marginTop: 1
                }}
            >

                <Chip 
                    label={desglose.ingreso_efectivo}
                    sx={{
                        padding: 3,
                        backgroundColor: 'green',
                        fontWeight: 'bold',
                        fontSize: 35
                    }}
                />

            </Grid>

            {/* CAMBIO REGRESADO */}

            <Grid item xs={6} marginTop={2}>

                <Typography 
                    textAlign={'center'}
                    variant='h3'
                    fontWeight={'bold'}
                >
                    Cambio:
                </Typography>

            </Grid>

            {/* RETIROS DE CAJA */}

            <Grid item xs={6} marginTop={2}>

                <Typography 
                    textAlign={'center'}
                    variant='h3'
                    fontWeight={'bold'}
                >
                    Retiros:
                </Typography>

            </Grid>

            {/* CAMBIO REGRESADO */}

            <Grid 
                item 
                xs={6} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    marginTop: 1
                }}
            >

                <Chip 
                    label={`- ${desglose.cambio}`}
                    sx={{
                        padding: 3,
                        backgroundColor: '#F39C12',
                        fontWeight: 'bold',
                        fontSize: 35
                    }}
                />

            </Grid>

            {/* RETIROS DE CAJA */}

            <Grid 
                item 
                xs={6} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    marginTop: 1
                }}
            >

                <Chip 
                    label={`- ${desglose.retiros}`}
                    sx={{
                        padding: 3,
                        backgroundColor: '#F39C12',
                        fontWeight: 'bold',
                        fontSize: 35
                    }}
                />

            </Grid>

            {/* SALDO FINAL */}

            <Grid item xs={12} marginTop={2}>

                <Typography 
                    textAlign={'center'}
                    variant='h3'
                    fontWeight={'bold'}
                >
                    Saldo Final:
                </Typography>

            </Grid>

            <Grid 
                item 
                xs={12} 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    marginTop: 1
                }}
            >

                <Chip 
                    label={desglose.saldo_final}
                    sx={{
                        padding: 3,
                        backgroundColor: '#5DADE2',
                        fontWeight: 'bold',
                        fontSize: 35
                    }}
                />

            </Grid>

            {/* FORMULARIO PARA CONFIRMAR SALDO FINAL */}

            <Grid item xs={12}>

                <Formulario 
                    idTurno={idTurno}
                    saldoFinal={desglose.saldo_final}
                    refresh={refresh}
                />

            </Grid>

        </Grid>

    )

}

export default DesgloseTurno