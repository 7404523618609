import React from 'react';
import { Formik, Form } from 'formik';
import { Fab, Grid } from "@mui/material";
import Swal from 'sweetalert2';

// ICONOS
import SearchIcon from '@mui/icons-material/Search';

// COMPONENTES
import MyDateInput from '../../../components/inputs/MyDateInput';

const Formulario = ( { refreshOrdenes } ) => {

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        if(values.start === '' || values.end === ''){

            Swal.fire({
                icon: 'warning',
                title: 'Seleccione una fecha para continuar'
            })

            return;

        }

        refreshOrdenes(values.start, values.end);

    }

    // ===============================================

    return (

        <Formik
                initialValues={{
                    start: '', 
                    end: '',
                }}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ values }) => (

                <Form>

                    <Grid container spacing={2}>

                        <Grid item xs={3}>

                            <MyDateInput
                                name="start"
                                label="Inicio"
                                format={'YYYY-MM-DD'}
                            />

                        </Grid>

                        <Grid item xs={3}>

                            <MyDateInput
                                name="end"
                                label="Fin"
                                format={'YYYY-MM-DD'}
                            />

                        </Grid>

                        {/* BOTON PARA BUSCAR */}

                        <Grid item xs={1}>

                            <Fab 
                                color="info"
                                onClick={() => handleSubmit(values)}
                                sx={{ 
                                    color: 'white' 
                                }}
                            >
                                <SearchIcon />
                            </Fab>

                        </Grid>

                    </Grid>

                </Form>

            )}
            </Formik>

    )

}

export default Formulario