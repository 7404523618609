import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';

// ICONOS
import PaidIcon from '@mui/icons-material/Paid';
import ModalCobrarOrden from './ModalCobrarOrden';

const BtnCobrarOrden = ({ articulos, total, idOrden, refreshOrdenes, tipoPedido }) => {
    
    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODAL
    // ===============================================

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {

        setShowModal(false);

    }

    // ===============================================
    // USE EFFECT PARA AGREGAR COBRO POR ENVIO
    // ===============================================

    const [totalInfo, setTotalInfo] = useState(total)

    useEffect(() => {

        if(tipoPedido === 'Envio'){

            const oldTotal = parseFloat(total.total.replace(/[^\d.-]/g, ''));
            const cobroEnvio = parseFloat(Cookies.get('costo_envio').replace(/[^\d.-]/g, ''));
            const newTotal = oldTotal + cobroEnvio;

            setTotalInfo({
                cuenta: total.cuenta,
                iva: total.iva,
                total: `$${newTotal.toFixed(2)}`
            })

        } else {

            setTotalInfo(total)

        }

    },[total, tipoPedido]);

    // ===============================================

    return (

        <React.Fragment>

            {/* BOTON PARA COBRAR */}
        
            <Button 
                variant="contained" 
                color="info" 
                fullWidth 
                sx={{ 
                    backgroundColor: '#0678C5', 
                    color: 'white',
                    fontWeight: 'bold'
                }}
                onClick={() => setShowModal(true)}
                startIcon={<PaidIcon/>}
            >
                Cobrar
            </Button>

            {/* MODAL PARA COBRAR */}

            <ModalCobrarOrden
                open={showModal}
                onClose={closeModal}
                articulos={articulos}
                total={totalInfo}
                idOrden={idOrden}
                refreshOrdenes={refreshOrdenes}
                tipoPedido={tipoPedido}
            />

        </React.Fragment>

    )

}

export default BtnCobrarOrden