import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, ButtonGroup, useTheme, TextField, Card, CardHeader, IconButton, CardActions, Typography, Divider, Table, TableBody, TableCell, TableRow, InputLabel, MenuItem, FormControl, Select, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { tokens } from '../../../theme';
import Swal from 'sweetalert2';
import { api } from '../../../config/axios';
import Cookies from 'js-cookie';

// ICONOS
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import Btn_Retiro_Dinero from '../subComponentes/retiroDinero/Btn_Retiro_Dinero';

const AgregarOrden = ({ refreshOrdenes, mesas }) => {

    const iva = Cookies.get('iva');
    const [disabledButton, setDisabled] = useState(false);
    const [usarIva, setUsarIva] = useState(false);

    // ===============================================
    // ESTADO PARA ACTUALIZAR MESA
    // ===============================================

    const [mesaSeleccionada, setMesa] = useState('');

    // ===============================================
    // ESTADO PARA ACTUALIZAR RADIOBUTTON
    // ===============================================

    const [disabledRadio, setDisabledRadio] = useState(true);
    const [radioValue, setRadioValue] = useState('Recoger');

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR ARTICULOS DISPONIBLES
    // ===============================================

    const [articulos, setArticulos] = useState([]);

    const buscarArticulos = async () => {

        const response = await api.get('inventario'); 

        if(response.data !== "Sin Resultados"){

            setArticulos(response.data);

        }else{

            setArticulos([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR CATEGORIAS
    // ===============================================

    const [categorias, setCategorias] = useState([]);

    const buscarCategorias = async () => {

        const response = await api.get('categorias/select');

        if(response.data !== "Sin Resultados"){

            setCategorias(response.data);

        }else{

            setCategorias([]);

        }

    }

    // ===============================================
    // FUNCION PARA FILTRAR ARTICULOS
    // ===============================================

    const filtrarArticulos = async ( idCategoria ) => {

        // SI EL ID ES IGUAL A ZERO LLAMAMOS LA FUNCION PRINCIPAL PARA TRAER TODOS LOS DATOS

        if(idCategoria !== 0){

            const response = await api.get(`inventario/${idCategoria}`); 

            if(response.data !== "Sin Resultados"){

                setArticulos(response.data);

            }else{

                setArticulos([]);

            }

        } else {

            buscarArticulos();

        }

    }

    // ===============================================
    // FUNCION PARA FILTRAR ARTICULOS POR TEXTO
    // ===============================================

    const filtrarArticulosPorTexto = ( value ) => {

        if(articulos.length > 0 && value !== ''){

            const filtro = articulos.filter( obj => obj.descripcion.toLowerCase().includes(value) || obj.descripcion.toLowerCase() === value.toLowerCase());

            setArticulos(filtro)

        } else {

            buscarArticulos();

        }

    }

    // ===============================================
    // ESTADO PARA TICKET DE ORDEN
    // ===============================================

    const [articulosOrden, setArticulosOrden] = useState([]);

    // ===============================================
    // FUNCION PARA ACTUALIZAR ESTADO DE LA ORDEN
    // ===============================================

    const updateOrden = ( item ) => {

        // VALIDAMOS SI YA EXISTE EL ARTICULO EN EL ESTADO

        let existeProducto = articulosOrden.find( producto => producto.id === item.id );

        // SI EXISTE EL PRODUCTO INCREMENTAMOS LA CANTIDAD EN +1

        if(existeProducto){

            aumentarCantidad(item)

        } else {

            // SI NO EXISTE LO AGREGAMOS

            const nuevoArticulo = { ...item, cantidad: 1 };
            const nuevoArrayArticulos = [...articulosOrden, nuevoArticulo];

            setArticulosOrden(nuevoArrayArticulos);

        }

    }

    // ===============================================
    // FUNCION PARA AUMENTAR CANTIDAD DE ARTICULO
    // ===============================================

    const aumentarCantidad = ( item ) => {

        // COPIAMOS LOS VALORES ACTUALES

        const articulosActuales = [...articulosOrden];

        // BUSCAMOS EL INDICE DEL PRODUCTO Y LO ACTUALIZAMOS

        articulosActuales.map((value, index) => {

            if(value.id === item.id){

                articulosActuales[index]['cantidad'] = articulosActuales[index]['cantidad'] + 1;

            }

        })

        // ACTUALIZAMOS EL VALOR

        setArticulosOrden(articulosActuales);

    }

    // ===============================================
    // FUNCION PARA RESTAR CANTIDAD DE ARTICULO
    // ===============================================

    const restarCantidad = ( item ) => {

        // COPIAMOS LOS VALORES ACTUALES

        const articulosActuales = [...articulosOrden];

        // BUSCAMOS EL INDICE DEL PRODUCTO Y LO ACTUALIZAMOS

        articulosActuales.map((value, index) => {

            if(value.id === item.id){

                if(item.cantidad > 1){

                    articulosActuales[index]['cantidad'] = articulosActuales[index]['cantidad'] - 1;

                }

            }

        })

        // ACTUALIZAMOS EL VALOR

        setArticulosOrden(articulosActuales);

    }

    // ===============================================
    // FUNCION PARA ELIMINAR ARTICULO
    // ===============================================

    const deleteArticulo = ( item ) => {

        Swal.fire({
            icon: 'question',
            title: item.descripcion,
            text: '¿ Desea Eliminar Este Articulo ?',
            confirmButtonText: "Eliminar",
            confirmButtonColor: 'red',
            showDenyButton: true,
            denyButtonColor: 'grey',
            denyButtonText: 'Cancelar'
        }).then((result) => {

            if(result.isConfirmed){

                // COPIAMOS LOS VALORES ACTUALES

                const articulosActuales = [...articulosOrden];

                const articulosPostDelete = articulosActuales.filter(obj => obj.id !== item.id);

                setArticulosOrden(articulosPostDelete);

            }

        })

    }

    // ===============================================
    // FUNCION PARA ACTUALIZAR TOTAL DEL CARRITO
    // ===============================================

    const [totalOrden, setTotalOrden] = useState({ cuenta: 0, iva: 0, total: 0 });

    const actualizarTotalOrden = () => {

        const copiaArticulos = [...articulosOrden];

        let nuevoTotal = { cuenta: 0, iva: 0, total: 0 };

        // RECORREMOS LOS ARTICULOS 

        copiaArticulos.map((item) => {

            let precio = parseFloat(item.precio.replace(/[^\d.-]/g, ''));

            const precioTotal = precio * item.cantidad;

            nuevoTotal.cuenta = nuevoTotal.cuenta + precioTotal;

        });

        const ivaAdicional = (iva / 100) * nuevoTotal.cuenta;

        nuevoTotal.iva = usarIva ? `$${ivaAdicional.toFixed(2)}` : '$0.00';
        nuevoTotal.total = usarIva ? `$${(ivaAdicional + nuevoTotal.cuenta).toFixed(2)}` : `$${nuevoTotal.cuenta.toFixed(2)}`;
        nuevoTotal.cuenta = `$${nuevoTotal.cuenta.toFixed(2)}`;

        setTotalOrden(nuevoTotal)

    }

    // ===============================================
    // USE EFFECT PARA DETECTAR CAMBIOS EN EL CARRITO
    // ===============================================

    useEffect(() => {

        actualizarTotalOrden();

    },[articulosOrden]);

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarCategorias();
        buscarArticulos();

        // VALIDAMOS SI SE USARA IVA
        setUsarIva(Cookies.get('aplica_iva') === 'SI' ? true : false);

    }, []);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = () => {

        // BLOQUEAMOS EL BOTON

        setDisabled(true);

        // VALIDAMOS QUE SE HAYA AGREGADO POR LO MENOS UN ARTICULO

        if(articulosOrden.length <= 0){

            Swal.fire({
                icon: 'warning',
                title: 'Agregue un articulo para continuar',
            });

            // HABILITAMOS DE NUEVO EL BOTON
            setDisabled(false);

            return;

        }

        // VALIDAMOS QUE SE HAYA SELECCIONADO ALGUNA MESA

        if(mesaSeleccionada === ''){

            Swal.fire({
                icon: 'warning',
                title: 'Seleccione una mesa para continuar',
            });

            // HABILITAMOS DE NUEVO EL BOTON
            setDisabled(false);

            return;

        }

        // GUARDAMOS LOS DATOS DE LA ORDEN

        const url = 'ordenes/crear';

        const values ={

            datosOrden: {

                cuenta: totalOrden.cuenta,
                iva: totalOrden.iva,
                total: totalOrden.total,
                status: 'Activa',
                mesa: mesaSeleccionada,
                tipo_pedido: radioValue,
                cobro_envio: radioValue === 'Envio' ? Cookies.get('costo_envio') : '$0.00'

            },
            articulosOrden: articulosOrden

        }

        // MANDAMOS EL POST

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                //refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                });

                // HABILITAMOS DE NUEVO EL BOTON
                setDisabled(false);

                // LIMPIAMOS LOS ESTADOS
                setTotalOrden({ cuenta: 0, iva: 0, total: 0 });
                setArticulosOrden([]);
                setMesa('');
                setRadioValue('Recoger');
                setDisabledRadio(true);

                // ACTUALIZAMOS CATALAGOS
                refreshOrdenes();

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Box>

            {/* CONTENEDOR PRINCIPAL */}

            <Grid container sx={{ height: '76vh' }}>

                {/* SECCION DE ARTICULOS */}

                <Grid item xs={8} sx={{ backgroundColor: colors.primary[300]}}>

                    <Grid container>

                        {/* HEADER */}

                        <Grid 
                            item xs={12} 
                            sx={{ 
                                backgroundColor: colors.primary[400],
                            }}
                        >

                            <Grid container>

                                {/* SELECT PARA MESAS */}

                                <Grid
                                    item
                                    xs={4}
                                    sx={{ padding: 2 }}
                                >

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Mesa</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            value={mesaSeleccionada}
                                            onChange={(event) => {
                                                setMesa(event.target.value)
                                                setDisabledRadio(event.target.value === 0 ? false : true);
                                            }}
                                        >
                                            <MenuItem value={''} > Seleccione una Mesa </MenuItem>
                                            {
                                                mesas.map((item, index) => (

                                                    <MenuItem 
                                                        value={item.value}
                                                        key={`${index}+${item.value}`}
                                                    >
                                                        {item.label}
                                                    </MenuItem>

                                                ))
                                            }
                                            <MenuItem value={0} > Pedido </MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                                {/* SECCION PARA TIPO DE PEDIDO */}

                                <Grid item xs={3} sx={{ padding: 1, display: 'flex', justifyContent: 'center' }}>

                                    <FormControl>

                                        <FormLabel id="demo-row-radio-buttons-group-label">Tipo Pedido</FormLabel>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={radioValue}
                                            onChange={(event) => {
                                                setRadioValue(event.target.value);
                                            }}
                                        >
                                            <FormControlLabel 
                                                value="Recoger" 
                                                disabled={disabledRadio} 
                                                control={
                                                    <Radio 
                                                        sx={{
                                                            '&.Mui-checked': {
                                                            color: 'green', // Color cuando está marcado
                                                            }
                                                        }} 
                                                    />
                                                } 
                                                label="Recoger" 
                                            />
                                            <FormControlLabel 
                                                value="Envio" 
                                                disabled={disabledRadio} 
                                                control={
                                                    <Radio 
                                                        sx={{
                                                            '&.Mui-checked': {
                                                            color: 'green', // Color cuando está marcado
                                                            }
                                                        }} 
                                                    />
                                                } 
                                                label={`Envio ${Cookies.get('costo_envio')}`}
                                            />
                                        </RadioGroup>

                                    </FormControl>

                                </Grid>

                                {/* SECCION PARA FILTRAR ARTICULOS */}

                                <Grid 
                                    item 
                                    xs={3} 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'start',
                                        padding: 2
                                    }}
                                >

                                    <Box>
                                        <SearchIcon 
                                            sx={{ 
                                                color: 'action.active', 
                                                mr: 1, 
                                                my: 0.5,
                                                marginTop: 2
                                            }} 
                                        />
                                        <TextField 
                                            label="Buscar Articulo" 
                                            variant="standard" 
                                            onBlur={(event) => filtrarArticulosPorTexto(event.target.value)}
                                        />
                                    </Box>

                                </Grid>

                                {/* BOTON PARA PROPINAS */}

                                <Grid 
                                    item 
                                    xs={1} 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        p: 2
                                    }}
                                >

                                    {/* <Btn_Retiro_Dinero/> */}

                                </Grid>

                                {/* BOTON PARA SACAR MONEY */}

                                <Grid 
                                    item 
                                    xs={1} 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        p: 2
                                    }}
                                >

                                    <Btn_Retiro_Dinero/>

                                </Grid>

                            </Grid>

                        </Grid>

                        {/* SECCION PARA CATEGORIAS */}

                        <Grid 
                            item 
                            xs={12} 
                            sx={{ 
                                backgroundColor: colors.primary[400], 
                                display: 'flex', 
                                justifyContent: 'center',
                                p: 2
                            }}
                        >

                            <ButtonGroup 
                                variant="contained" 
                                aria-label="Basic button group"
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1,
                                }}
                            >

                                {/* BOTON ESTATICO PARA RESET */}

                                <Button 
                                    sx={{
                                        flexGrow: 1,
                                        background: 'white',
                                        color: 'black',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: colors.main[500],
                                            color: 'white', 
                                        },
                                    }}
                                    onClick={() => filtrarArticulos(0)}
                                >
                                    TODOS
                                </Button>
                                
                                {
                                    categorias.map((item, index) => (

                                        <Button 
                                            key={`${item.id}-${index}`}
                                            sx={{
                                                flexGrow: 1,
                                                background: 'white',
                                                color: 'black',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: colors.main[500],
                                                    color: 'white', 
                                                },
                                            }}
                                            onClick={() => filtrarArticulos(item.value)}
                                        >
                                            {item.label}
                                        </Button>

                                    ))}
                            </ButtonGroup>

                        </Grid>

                        {/* SECCION PARA ARTICULOS */}

                        <Grid 
                            container 
                            sx={{ 
                                overflowY: 'scroll', 
                                scrollbarWidth: 'none', 
                                'msOverflowStyle': 'none', 
                                height: '63vh' 
                            }}
                        >

                            {
                                articulos.map((item, index) => (

                                    <Grid 
                                        item 
                                        xs={4}
                                        sx={{ padding: 1 }}
                                        key={item.id}
                                    >

                                        <Card 
                                            sx={{ 
                                                width: '100%', 
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'center', 
                                                justifyContent: 'center' 
                                            }}
                                        >

                                            <CardHeader
                                                
                                                title={
                                                    <Typography variant="h4" align="center" fontWeight={'bold'}>
                                                        {item.descripcion}
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography variant="h6" align="center">
                                                        {item.precio}
                                                    </Typography>
                                                }

                                            />

                                            <IconButton 
                                                onClick={() => updateOrden(item)}
                                            >
                                                <FastfoodIcon 
                                                    sx={{ fontSize: 120 }}
                                                />
                                            </IconButton>

                                            <CardActions sx={{ justifyContent: 'center', display: 'flex'}}>

                                                <IconButton 
                                                    aria-label="add-item"
                                                    onClick={() => updateOrden(item)}
                                                >
                                                    <AddCircleIcon />
                                                </IconButton>
                        
                                            </CardActions>

                                        </Card>

                                    </Grid>

                                ))
                            }

                        </Grid>

                    </Grid>
     
                </Grid>

                {/* SECCION DE TICKET */}

                <Grid item xs={4} sx={{ backgroundColor: 'white'}}>

                    {/* HEADER DEL TICKET */}

                    <Grid container>

                        <Grid item xs={12}>

                            <Typography variant='h1' sx={{ color: 'black'}} textAlign={'center'} marginTop={1} fontWeight={'bold'}>
                                Orden
                            </Typography>

                        </Grid>

                        <Grid item xs={12}>

                            <Divider sx={{ borderColor: 'black', borderWidth: 1, m: 2 }}/>

                        </Grid>

                    </Grid>

                    {/* CUERDO DEL TICKET */}

                    <Grid 
                        container 
                        sx={{ 
                            p: 2, 
                            overflow: 'auto', 
                            height: '58vh',
                            '&::-webkit-scrollbar': {
                                width: '0px',
                                background: 'transparent',
                            },
                        }}
                    >

                        <Grid item xs={12}>

                            <Table sx={{ width: '100%' }} aria-label="crear-orden">

                                <TableBody>
                                    {
                                        articulosOrden.map((item) => (

                                            <TableRow
                                                key={item.id}
                                            >

                                                {/* BOTON PARA ELIMINAR ARTICULO  */}

                                                <TableCell>
                                                    
                                                    <IconButton 
                                                        sx={{ 
                                                            backgroundColor: 'red',
                                                            '&:hover': {
                                                                    backgroundColor: 'black',
                                                                    color: 'white', 
                                                                },
                                                        }} 
                                                        size="small"
                                                        onClick={() => deleteArticulo(item)}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>

                                                </TableCell>

                                                {/* BOTON PARA DESCRIPCION DEL PRODUCTO */}

                                                <TableCell 
                                                    sx={{ 
                                                        color: 'black', 
                                                        fontSize: 18 
                                                    }} 
                                                    align="center"
                                                >
                                                    {item.descripcion}
                                                </TableCell>

                                                {/* MONTO DEL PRODUCTO */}

                                                <TableCell 
                                                    sx={{ 
                                                        color: 'black', 
                                                        fontSize: 18, 
                                                        fontWeight: 'bold' 
                                                    }} 
                                                    align="center"
                                                >
                                                    {item.precio}
                                                </TableCell>

                                                {/* BOTON PARA RESTAR 1 PRODUCTO */}

                                                <TableCell>
                                                    
                                                    <IconButton 
                                                        sx={{ 
                                                            backgroundColor: 'black',
                                                            '&:hover': {
                                                                    backgroundColor: 'grey',
                                                                    color: 'white', 
                                                                },
                                                        }} 
                                                        size="small"
                                                        onClick={() => restarCantidad(item) }
                                                    >
                                                        <RemoveIcon/>
                                                    </IconButton>

                                                </TableCell>

                                                {/* BOTON PARA CANTIDAD DE PRODUCTO */}

                                                <TableCell 
                                                    sx={{ 
                                                        color: 'black', 
                                                        fontSize: 18, 
                                                        fontWeight: 'bold' 
                                                    }} 
                                                    align="center"
                                                >
                                                    {item.cantidad}
                                                </TableCell>

                                                {/* BOTON PARA AGREGAR 1 PRODUCTO */}

                                                <TableCell>
                                                    
                                                    <IconButton 
                                                        sx={{ 
                                                            backgroundColor: 'black',
                                                            '&:hover': {
                                                                    backgroundColor: 'grey',
                                                                    color: 'white', 
                                                                },
                                                        }} 
                                                        size="small"
                                                        onClick={() => aumentarCantidad(item)}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>

                                                </TableCell>

                                            </TableRow>

                                        ))

                                    }

                                </TableBody>

                            </Table>

                        </Grid>

                    </Grid>

                    {/* FOOTER DEL TICKET */}

                    <Grid container>

                        <Grid item xs={4} sx={{ backgroundColor: 'black'}}>

                            <Typography 
                                variant='h5' 
                                textAlign={'center'} 
                                marginTop={1}
                            >
                                subTotal:
                            </Typography>

                        </Grid>

                        <Grid item xs={4} sx={{ backgroundColor: 'black'}}>

                            <Typography 
                                variant='h5' 
                                textAlign={'center'} 
                                marginTop={1}
                            >
                                Iva ({iva}%):
                            </Typography>

                        </Grid>

                        <Grid item xs={4} sx={{ backgroundColor: 'black'}}>

                            <Typography 
                                variant='h5' 
                                textAlign={'center'}
                                marginTop={1} 
                            >
                                Total:
                            </Typography>

                        </Grid>

                        <Grid item xs={4} sx={{ backgroundColor: 'black'}}>

                            <Typography 
                                variant='h3' 
                                textAlign={'center'} 
                                padding={1} 
                                fontWeight={'bold'}
                            >
                                {totalOrden.cuenta}
                            </Typography>

                        </Grid>

                        <Grid item xs={4} sx={{ backgroundColor: 'black'}}>

                            <Typography 
                                variant='h3' 
                                textAlign={'center'} 
                                marginTop={1} 
                                fontWeight={'bold'}
                            >
                                {`${totalOrden.iva}`}
                            </Typography>

                        </Grid>

                        <Grid item xs={4} sx={{ backgroundColor: 'black'}}>

                            <Typography 
                                variant='h3' 
                                textAlign={'center'} 
                                padding={1} 
                                fontWeight={'bold'}
                            >
                                {totalOrden.total}
                            </Typography>

                        </Grid>

                        {/* GUARDAR ORDEN */}

                        <Grid 
                            item 
                            xs={12} 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                backgroundColor: 'green' 
                            }}
                        >

                            <Button 
                                variant="contained" 
                                color="success" 
                                fullWidth 
                                sx={{ 
                                    backgroundColor: 'green', 
                                    color: 'white',
                                    fontWeight: 'bold'
                                }}
                                disabled={disabledButton}
                                onClick={handleSubmit}
                                startIcon={<SaveIcon />}
                            >
                                Guardar
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>

            </Grid>

        </Box>

    )

}

export default AgregarOrden