import { useEffect, useState } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { tokens } from "../../theme";

import SearchIcon from "@mui/icons-material/Search";
import LogOut from "./menu/logout/LogOut";
import Settings from "./menu/settings/Settings";

const Topbar = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const [isMobile, setIsMobile] = useState(false);

    const checkIsMobile = () => {

        setIsMobile(window.innerWidth < 768); // Puedes ajustar este valor según tus necesidades

    };

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        checkIsMobile()

    },[]);

    // ===============================================

    return (

        <Box 
            display="flex" 
            justifyContent={isMobile ? `end` : "space-between"} 
            p={2}
        >

            {/* SEARCH BAR */}

            {
                isMobile ? (

                null

                )
                : (

                <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                >
                    <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>

                )
            }
        
            {/* ICONS */}

            <Box display="flex">

                {/* SETTINGS */}

                {/* <Settings/> */}

                {/* LOGOUT */}

                <LogOut/>

            </Box>

            {/* MODAL PARA SETTINGS */}

        </Box>

    )

}

export default Topbar