import React from 'react';

// COMPONENTES
import MiTabla from '../../../../../../components/plantilla/MiTabla';
import { Grid, Typography, Divider } from '@mui/material';

const TablaResumen = ({ data }) => {

    // ===============================================
    // COLUMNAS PARA TABLA
    // ===============================================

    const columns = [

        {
            field: "id",
            headerName: "Orden",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: (cell) => `# ${cell.value}`,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "total",
            headerName: "Total",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "pago_efectivo",
            headerName: "Efectivo",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "pago_tarjeta",
            headerName: "Tarjeta",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "cambio",
            headerName: "Cambio",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================

    return (

        <Grid container>

            <Grid item xs={12}>

                <Typography
                    textAlign={'center'} 
                    variant='h2' 
                    marginBottom={1}
                >
                    Resumen Ordenes
                </Typography>

            </Grid>

            <Grid item xs={12}>

                <Divider></Divider>

            </Grid>

            {/* TABLA */}

            <Grid item xs={12}>

                <MiTabla
                    data={data}
                    columns={columns}
                />

            </Grid>

        </Grid>

    )

}

export default TablaResumen